import {
    ArrayField,
    BooleanField, Button,
    Datagrid,
    DateField, EditButton,
    EmailField,
    Show,
    SimpleShowLayout,
    TextField, TopToolbar,
    UrlField, useNotify, useRefresh, useShowContext
} from 'react-admin';
import SyncIcon from '@mui/icons-material/Sync';

const SyncButton = () => {
    const {record} = useShowContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        const urlSync = `${process.env.REACT_APP_API_URL}/companies/sync-lucca/${record.id}`;
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `JWT ${token}` },
            responseType: 'blob',
            method: 'POST'
        }
        console.log(urlSync);
        fetch(urlSync, config)
            .then(data => {
                notify('Synchronisation with LUCCA : Done', {type: 'success', undoable: false});
                refresh();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return <Button label="Sync with LUCCA" onClick={handleClick}>
        <SyncIcon/>
    </Button>;
};

const CompanyShowActions = () => (
    <TopToolbar>
        <EditButton />
        <SyncButton />
    </TopToolbar>
);

const CompanyShow = () => (
    <Show actions={<CompanyShowActions />} >
        <SimpleShowLayout>
            <TextField source="name" label="Company Name" />
            <UrlField source="luccaURL" label="Lucca URL" emptyText="-"  />
            <TextField  source="luccaApiKey" label="Lucca API Key" emptyText="-" />
            <BooleanField source="isDisabled" />
            <hr/>
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <TextField source="id" />
            <hr/>
            <ArrayField source="collaborators" sort={{ field: 'lastname', order: 'ASC' }}>
                <Datagrid emptyText="Aucun collaborateur">
                    <TextField source="lastname" />
                    <TextField source="firstname" />
                    <EmailField source="email" />
                    <TextField source="jobTitle" />
                    <TextField source="departmentTitle" />
                </Datagrid>
            </ArrayField>

        </SimpleShowLayout>
    </Show>
);

export default CompanyShow;