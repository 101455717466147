import {Datagrid, DateField, List, NumberField, ReferenceField, TextField} from 'react-admin';

const FormMissionsRHList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="domain" label="Domaine de formation" />
            <TextField source="niveau" />
            <TextField source="format" />
            <TextField source="localisation" />
            <ReferenceField source="author" reference="rhs" />
            <NumberField source="nbCollaborators" />
            <DateField source="startDate" />
            <TextField source="duree" />
            <TextField source="modeFinancing" label="Financement" />
            <NumberField source="budget" />
        </Datagrid>
    </List>
);

export default FormMissionsRHList;