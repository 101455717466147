import {
    BooleanField,
    Datagrid,
    DateField,
    EditButton, FunctionField,
    List,
    SearchInput,
    ShowButton,
    TextField,
    UrlField
} from 'react-admin';

const CompanyList = () => {
    const companyFilters = [
        <SearchInput key='Search' source='q' alwaysOn />,
    ];

    return (
        <List filters={companyFilters} sort={{field: 'name', order: 'ASC'}}>
            <Datagrid rowClick="show">
                <TextField source="name"/>
                <UrlField source="luccaURL" label="Lucca URL" type="url"/>
                <TextField source="luccaApiKey" label="Lucca API Key"/>
                <FunctionField label="Collaborators" render={record => record.collaborators.length}/>
                <BooleanField source="isDisabled"/>
                <DateField source="createdAt" label="Created At"/>
                <ShowButton/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};


export default CompanyList;