import {BooleanInput, DateInput, Edit, ReferenceInput, SimpleForm, TextInput} from 'react-admin';

const RhEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <ReferenceInput source="company" reference="companies" />
            <hr/>
            <BooleanInput source="isDisabled" />
            <DateInput source="lastLoginAt" />
            <TextInput source="id" />
        </SimpleForm>
    </Edit>
);

export default RhEdit;