import {BooleanInput, Edit, SimpleForm, TextInput} from 'react-admin';

const CompanyEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" label="Company Name" />
            <TextInput source="luccaURL" label="Lucca URL" />
            <TextInput source="luccaApiKey" label="Lucca API Key" />
            <BooleanInput source="isDisabled" />
        </SimpleForm>
    </Edit>
);

export default CompanyEdit;