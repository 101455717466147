import {DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

const FormMissionsRHShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="domain" label="Domaine de formation / compétences :"/>
            <TextField source="niveau"  label="Niveau de la formation :"/>
            <TextField source="format"  label="Format de la formation :"/>
            <TextField source="localisation"  label="Lieu de la formation souhaité :"/>
            <TextField source="duree"  label="Durée de la formation :" />
            <NumberField source="nbCollaborators"  label="Nombre de collaborateurs à former :"/>
            <DateField source="startDate"  label="Date de début envisagée :"/>
            <TextField source="modeFinancing"  label="Mode de financement :"/>
            <NumberField source="budget" label="Budget HT envisagé (€):" />
            <TextField source="comments"  label="Commentaires :"/>

            <hr/>
            <ReferenceField source="author" reference="rhs" label="Auteur de la demande" />

            <hr/>
            <DateField source="createdAt" label="Demande crée le" />
            <DateField source="updatedAt" label="Dernière mise à jour" />
            <TextField source="id" />
        </SimpleShowLayout>
    </Show>
);

export default FormMissionsRHShow;