import {
    BooleanField,
    Datagrid,
    DateField,
    DateTimeInput,
    EmailField,
    List,
    ReferenceField,
    SearchInput,
    TextField
} from 'react-admin';

const RhList = () => {

    const postFilters = [
        <SearchInput key='Search' source='q' alwaysOn />,
        <DateTimeInput key='createdAt_gte' source='createdAt_gte' label='Created after' />,
        <DateTimeInput key='createdAt_lte' source='createdAt_lte' label='Created before' />,
        <DateTimeInput key='lastLoginAt_gte' source='lastLoginAt_gte' label='Last login after' />,
        <DateTimeInput key='lastLoginAt_lte' source='lastLoginAt_lte' label='Last login before' />
    ];

    return (
        <List filters={postFilters} filter={{ deletedAt: null }} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="lastname"/>
                <TextField source="firstname"/>
                <EmailField source="email"/>
                <TextField source="phone"/>
                <ReferenceField reference="companies" source="company" link="show"/>
                <BooleanField source="isDisabled"/>
                <DateField source="lastLoginAt"/>
            </Datagrid>
        </List>
    );
};

export default RhList;