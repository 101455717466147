import {ReferenceInput, useResourceContext} from 'react-admin';
import { getCustomTableEdit } from '../components/CustomTableEdit';
import {useEffect, useState} from 'react';


const nonEditableFields = [
    'createdAt',
    'updatedAt',
];


const dataHandler = (fields, fieldName, fieldList, index) => {

    if (nonEditableFields.includes(fieldName)) {
        return true;
    }

    if (fieldName === 'trainer') {
        fieldList.push(
            <ReferenceInput key={index} source={fieldName} reference="trainers" label="Formateur" filter={{status: 'validated'}} sort={{field: 'lastname', order: 'ASC'}} perPage={1000}/>
        );
        return true;
    }

    return false;
}


const TrainerOrientationEdit = (props) => {
    const resource = useResourceContext(props);
    const [data, setData] = useState();

    useEffect(() => {
        getCustomTableEdit(resource, props, dataHandler, {isCreate: false}).then((data) => setData(data));
    }, [props, resource]);

    if (data == null) {
        return <div></div>;
    }
    return data;
};

export default TrainerOrientationEdit;