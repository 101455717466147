import {BooleanInput, Create, SimpleForm, TextInput} from 'react-admin';

const CompanyCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" label="Company Name" required={true} />
            <TextInput type="url" source="luccaURL" label="Lucca URL" />
            <TextInput source="luccaApiKey" label="Lucca API Key" />
            <BooleanInput source="isDisabled" />
        </SimpleForm>
    </Create>
);

export default CompanyCreate;